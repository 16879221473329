<template>
  <li
    :class="liClassList"
    :data-type="question.type"
    :id="`id_${question.qid}`">
    <control-label :question="question" />
    <div
      :id="`cid_${question.qid}`"
      class="form-input-wide jf-required">
      <input
        v-bind="attributes"

        @input="$emit('input', $event.target.value)"
      >
      <control-sub-label
        v-if="subLabel"
        :question="question">{{ subLabel }}</control-sub-label>
    </div>
  </li>
</template>

<script>
  import is from 'is_js'
  import fieldMixins from './fieldMixins'

  export default {
    mixins: [fieldMixins],
    props: {
      question: {
        type: Object,
        required: true
      },
      value: {
        type: [String, Number],
        default: ''
      }
    },
    computed: {
      type () {
        if ((this.isNumberControl || is.number(this.value)) && !this.isReadOnly) {
          return {
            name: 'number',
            data: 'input-number',
            className: 'form-number-input',
            component: 'number'
          }
        }

        if (is.email(this.value)) {
          return {
            name: 'email',
            data: 'input-textbox',
            className: 'form-textbox',
            component: 'email'
          }
        }

        // Default Text Box
        return {
          name: 'text',
          data: 'input-textbox',
          className: 'form-textbox',
          component: 'textbox'
        }
      },
      subLabel () {
        if (is.email(this.value)) {
          return ' example@example.com '
        }

        return null
      },
      formattedValue () {
        if (is.not.function(this.question.formatter)) return this.value

        return this.question.formatter(this.value)
      },
      attributes () {
        // Initial Attributes
        let attributesObject = {
          'class': `${this.type.className} form-control form-control-lg validate[required]`,
          'type': this.type.name,
          'id': `input_${this.question.qid}`,
          'name': `q${this.question.qid}_${this.question.name}`,
          'size': this.question.size,
          'value': this.formattedValue,
          'placeholder': this.question.hint,
          'data-type': this.type.data,
          'data-component': this.type.component
        }

        // Conditional Attributes

        // Add required attribute
        if (this.isRequired) attributesObject['required'] = 'required'

        // Add readonly attribute
        if (this.isReadOnly) attributesObject['readonly'] = 'readonly'

        // Number input attributes
        if (this.type.name === 'number') attributesObject = {
          ...attributesObject,
          'step': '0.01', // Numbers like 21.51 will error without this
          'min': this.question.minValue || 0
        }

        return attributesObject
      }
    }
    // mounted() {}
  }
</script>
