<template>
  <label
    :for="`input_${question.qid}`"
    class="form-sub-label"
    style="min-height:13px">
    <slot />
  </label>
</template>

<script>
  export default {
    props: {
      question: {
        type: Object,
        required: true
      }
    }
  }
</script>
