<template>
  <li
    :class="liClassList"
    :id="`id_${question.qid}`"
    data-type="control_divider">
    <hr >
  </li>
</template>

<script>
  import fieldMixins from './fieldMixins'

  export default {
    mixins: [fieldMixins],
    props: {
      question: {
        type: Object,
        required: true
      }
    }
  }
</script>
