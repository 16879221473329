import is from 'is_js'
import ControlLabel from './parts/Label.vue'
import ControlSubLabel from './parts/SubLabel.vue'

export default {
  components: {
    'control-label': ControlLabel,
    'control-sub-label': ControlSubLabel,
  },
  computed: {
    isRequired () {
      return this.affirm(this.question.required)
    },
    isHidden () {
      if (is.boolean(this.question.hidden)) {
        return this.question.hidden
      }

      return this.affirm(this.question.hidden)
    },
    isReadOnly () {
      const isCalculatedField = this.question.type === 'control_calculation'
      return this.affirm(this.question.readonly) || isCalculatedField
    },
    isNumberControl () {
      return this.question.type === 'control_number'
    },
    fieldName () {
      return this.makeFieldName(this.question)
    },
    liClassList () {
      // console.log(`From li: ${this.question.name} is boolean`, this.question.hidden)
      // console.log(`From li: this.isHidden`, this.isHidden)
      return {
        'form-line': true,
        'jf-required': this.isRequired,
        'd-none': this.isHidden
      }
    }
  },
  methods: {
    affirm (value) {
      // Undefined? Then it's false
      if (typeof value === 'undefined') return false
      // Capture value and make lowercase
      const lowerCaseValue = value.toLowerCase()
      // Is it an affirmative word?(yes, y, okay, ok, true, t)
      return is.affirmative(lowerCaseValue)
    },
    isInitialQuestion (question) {
      return question.name === 'howMany' || question.name === 'howMany10'
    },
    makeFieldName (question) {
      return `q${question.qid}_${question.name}`
    },
    fieldClassName (field) {
      // console.log('field', field)
      if(!field) {
        return ''
      }
      if((field.$touched || field.$submitted) && field.$valid) {
        return 'has-success'
      }
      if((field.$touched || field.$submitted) && field.$invalid) {
        return 'has-danger'
      }
    }
  }
  // watch: {
  // 	value: function(newVal, oldVal) { // watch it
  //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
  //   }
  // }
}
