<template>
  <li
    :class="liClassList"
    :data-type="question.type"
    :id="`id_${question.qid}`">
    <label
      :id="`label_${question.qid}`"
      :for="`input_${question.qid}`"
      class="form-label form-label-top form-label-auto">
      {{ question.text }}
      <span class="form-required">
        *
      </span>
    </label>
    <div
      :id="`cid_${question.qid}`"
      class="form-input-wide jf-required">

      <select
        :id="`input_${question.qid}`"
        :name="`q${question.qid}_${question.name}`"
        :required="(isRequired) ? 'true' : 'false'"
        class="form-control form-control-lg form-dropdown form-control form-control-lg validate[required]"

        data-component="dropdown"
        @input="$emit('input', $event.target.value)"
      >
        <option
          v-for="(option, i) in options"
          :key="i"
          :data-calcvalue="option.value"
          :value="option.value"
        > {{ option.label }} </option>
      </select>
    </div>
  </li>


</template>

<script>
  import {trimCharacter} from '../../helpers'
  import fieldMixins from './fieldMixins'

  export default {
    mixins: [fieldMixins],
    props: {
      question: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        selected: '1 Color'
      }
    },
    computed: {
      options () {
        const optionNames = this.question.options.split('|'),
          optionValues = trimCharacter(this.question.calcValues, '|').split('|'),
          options = []


        // Build options
        optionValues.forEach((value, i) => {
          options.push({
            label: optionNames[i],
            value
          })
        })

        return options
      }
    }
  }
</script>
