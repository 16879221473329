<template>
  <li
    :class="submitLiClassList"
    :id="`id_${question.qid}`"
    data-type="control_button">
    <button
      type="submit"
      class="btn btn-secondary btn-lg"
      data-component="button"
      @click="onClick"
    >{{ question.text }}</button>
  </li>
</template>

<script>
  import fieldMixins from './fieldMixins'

  export default {
    mixins: [fieldMixins],
    props: {
      question: {
        type: Object,
        required: true
      },
      onClick: {
        type: Function,
        required: true
      }
    },
    computed: {
      submitLiClassList () {
        return {
          ...this.liClassList,
          'text-center': true
        }
      }
    }
  }
</script>
