<template>
  <label
    :id="`label_${question.qid}`"
    :for="`input_${question.qid}`"
    class="form-label form-label-top form-label-auto">
    {{ question.text }}
    <span
      v-if="(question.required === 'Yes')"
      class="form-required">
      *
    </span>
  </label>
</template>

<script>
  export default {
    props: {
      question: {
        type: Object,
        required: true
      }
    }
  }
</script>
