<template>
  <div class="container">
    <div class="row justify-content-center">
      <div v-if="currentFormState !== 'ready'" class="col-md-6 d-flex text-center minh-100 align-items-center justify-content-center bg-light">
        <div class="state-label font-weight-bold text-size-6">{{ formStates[currentFormState].label }}</div>
      </div>
      <div v-else class="col-md-6">
        <div v-if="questions">

          <form
            ref="form"
            :name="`form_${formId}`"
            @submit.prevent="submit"
          >
            <input
              :value="formId"
              type="hidden"
              name="formID">
            <div
              v-for="(question, i) in questions"
              :key="i">
              <component
                :is="question.type"
                :question="question"
                v-model="question.value"
              />
            </div>

            <div v-if="!howManyShirtsIsValid">
              Please increase number of shirts to at least 12.
            </div>

            <div class="pricing-message h4 text-center p-4">
              {{ message }}
            </div>

            <div>
              <submit
                :question="submitButton"
                :on-click="() => null"
              />
            </div>

          </form>

        </div>
      </div>
      <!-- <div class="col-sm-6">
        Questions Peview
        <pre>{{ valuesPreview }}</pre>
        Questions
        <pre>{{ questions }}</pre>
        <hr>
      </div> -->
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import is from 'is_js'
  import config from '../config'
  import {formatDollars, jsonFreeze} from '../helpers'
  import getClothingProduct from '../helpers/clothingProducts'
  import {pricingTiers} from '../helpers/pricingTiers'
  import getMessage from '../helpers/messages'
  import fieldMixins from './fields/fieldMixins'
  import ControlInput from './fields/Input'
  import ControlDropdown from './fields/Dropdown'
  import ControlButton from './fields/Submit'
  import ControlDivider from './fields/Divider'

  let rawQuestions

  export default {
    components: {
      'control_textbox': ControlInput,
      'control_calculation': ControlInput,
      'control_number': ControlInput,
      'control_email': ControlInput,
      'control_dropdown': ControlDropdown,
      'control_divider': ControlDivider,
      'control_button': () => null,
      'submit': ControlButton
    },
    mixins: [fieldMixins],
    props: {
      formId: {
        type: String,
        default: process.env.JOTFORM_ID
      }
    },
    data () {
      return {
        data: null,
        clothingProduct: null,
        questions: null,
        screenCharge: true,
        submitButton: null,
        messageKey: 'nextTier',
        nextTier: null,
        howManyShirtsIsValid: null,
        currentFormState: 'loading',
        formStates: {
          'loading': {
            label: 'Loading'
          },
          'ready': {
            label: ''
          },
          'submitted': {
            label: "Thanks! We'll get back to you shortly. "
          },
          'failed': {
            label: 'Oops! An error occured. '
          }
        }
      }
    },
    watch: {
      questions: {
        handler: function (questions) {
          if (is.not.array(this.questions)) return

          // Collect computed properties for calculated questions
          const calculatedQuestions = this.calculateQuestions()

          const howManyShirts = this.getQuestionByName('howMany')
          this.howManyShirtsIsValid = howManyShirts.value > Number(howManyShirts.minValue)

          this.questions.forEach((question, i) => {

            // Get raw question
            const rawQuestion = rawQuestions.find((q) => (Number(q.order)-1 === i))

            // Hide the rest of the questiions until shirts is valid
            if (!this.howManyShirtsIsValid && !this.isInitialQuestion(question)) {
              this.questions[i].hidden = true
              // console.log('Hiding', rawQuestion.name, rawQuestion.hidden, rawQuestion)
            } else if (this.howManyShirtsIsValid) {
              this.questions[i].hidden = rawQuestion.hidden || false
              // console.log('Unhiding', rawQuestion.name, rawQuestion.hidden, rawQuestion)
            }

            // This is how calculated questions are calculated
            if (is.propertyDefined(calculatedQuestions, question.name)) {
               this.questions[i].value = calculatedQuestions[question.name]
            }
          })

          // console.log('this.questions', this.questions)
        },
        deep: true,
        immediate: true
      },
    },
    computed: {
      valuesPreview () {
        const preview = {}

        if (this.questions) {
          this.questions.forEach((question) => {
            preview[question.name] = question.value
          })
        }

        return preview
      },
      message () {
        if (!this.nextTier) return ''
        // Build data object for message
        const data = {
          shirtCount: this.getValueByName('howMany'),
          nextTier: this.nextTier,
          nextTierAmount: this.costPerShirt(this.nextTier),
        }
        // Get the message
        const currentMessage = getMessage(this.messageKey, data)
        return currentMessage.value
      }
    },
    mounted () {
      this.getFormData()
    },
    methods: {
      byOrder (a, b) {
        if (Number(a.order) < Number(b.order)) return -1
        if (Number(a.order) > Number(b.order)) return 1
        return 0
      },
      getFormatter (question) {
        const formatedFields = {
          'perShirt': formatDollars
        }

        let formatter = null

        if (is.propertyDefined(formatedFields, question.name)) {
          formatter = formatedFields[question.name]
        }

        return formatter
      },
      getQuestionByName (name, questions = this.questions) {
        return questions.find((question) => {
          return question.name === name
        })
      },
      getValueByName (name, questions = this.questions) {
        return Number(questions.find((question) => {
          return question.name === name
        }).value)
      },
      getTotalColorCost (tier) {
        // Set screenCharge
        this.screenCharge = tier.screenCharge

        // Get totalColors
        const totalColors = this.getValueByName('howMany10')
        // Multiply costPerColor with totalColor
        const additionalColorTotal = (tier.costPerColor * totalColors)

        // Add baseColorCost to total cost of colors
        return (tier.baseColorCost + additionalColorTotal)
      },
      getCurrentPricingTier () {
        // Get shirtCount
        const shirtCount = this.getValueByName('howMany')
        // Is more than or equal to min
        // and less than or equal to max
        const isBetween = (min, max) => (min <= shirtCount && shirtCount <= max)

        let nextTier

        const tier = pricingTiers.find((tier, i) => {
          nextTier = pricingTiers[i+1]
          // Bind and fire range method
          return isBetween(tier.min, tier.max)
        })

        this.nextTier = nextTier

        return tier
      },
      // colorCost (tier) {
      //   return this.getTotalColorCost(tier)
      // },
      costPerShirt (pricingTier) {
        // Get values
        const baseColorCost = this.getTotalColorCost(pricingTier)
        const locationOneColors = Number(this.getValueByName('howMany10'))
        const perColor = Number(this.getValueByName('perColor'))
        const clothingCost = Number(this.getValueByName('clothingCost'))

        // Cost of this location's colors
        const totalColorCost = perColor * locationOneColors
        // Cost this location
        const locationCost = baseColorCost + totalColorCost
        // Cost of clothing and location
        const sum = clothingCost + locationCost
        return sum
      },
      locationCost (howManyColors) {
        const baseColor = this.colorCost()
        const perColor = Number(this.getValueByName('perColor'))
        const totalColorCost = perColor * howManyColors

        return baseColor + totalColorCost
      },
      calculateQuestions () {
        const pricingTier = this.getCurrentPricingTier()
        const locationOneColors = Number(this.getValueByName('howMany10'))
        // const locationOneCost = this.locationCost(locationOneColors)
        // const locationTwoCost = this.locationCost(locationTwoColors)
        return {
          'perShirt': this.costPerShirt(pricingTier),
          // 'location1': locationOneCost,
          'baseColor': this.getTotalColorCost(pricingTier)
        }
      },
      preFilledQuestions () {
        return [
          {
            name: 'clothingName',
            value: this.clothingProduct.clothing_name
          },
          {
            name: 'clothingCost',
            value: this.clothingProduct.price
          }
        ]
      },
      parseQuestions (rawQuestions) {
        let parsedQuestions = []
        const preFilledQuestions = this.preFilledQuestions()

        Object.values(rawQuestions).forEach((rawQuestion) => {
          const question = jsonFreeze(rawQuestion)

          // Vue can't detect additions or deletions
          // and jotform leaves out some properties
          // depending on the type of field so we
          // need to make sure define every reactive
          // property that will be used before it's
          // loaded into this.questions.
          // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats

          question.fieldName = this.makeFieldName(rawQuestion)
          question.formatter = this.getFormatter(rawQuestion)
          question.value = rawQuestion.defaultValue
          question.hidden = rawQuestion.hidden || false

          const preFilledQuestion = preFilledQuestions.find((preFilledQuestion) => preFilledQuestion.name === question.name)
          if (typeof preFilledQuestion !== 'undefined') question.value = preFilledQuestion.value

          // console.log('rawQuestions[key].hidden', rawQuestions[key].hidden)

          parsedQuestions.push(question)
        })

        parsedQuestions = parsedQuestions.sort(this.byOrder)

        return parsedQuestions
      },
      initValues () {
        // Set seed data

        // // q6_clothingName
        // this.questions[0].value = clothingProduct.clothing_name
        // // q4_clothingCost
        // this.questions[1].value = clothingProduct.price


        // Calculated values

        // q8_baseColor
        this.questions[2].value = '3.10'
        // q9_perColor
        this.questions[3].value = '1.20'
        // q10_howMany10
        this.questions[7].value = '1'

        // Calculated

        // q7_location1
        this.questions[4].value = 0
        // q3_perShirt
        // this.questions[8].value = formatDollars(this.costPerShirt)
      },
      getFormData: async function() {
        this.clothingProduct = await getClothingProduct()

        axios.get(`https://okink-cacher.now.sh/form/${this.formId}`).then((response) => {
          // Create array of original response and freeze
          // it so that it doesn't get updated
          rawQuestions = jsonFreeze(Object.values(response.data.content))
          // Store original response
          this.response = jsonFreeze(response.data)
          this.questions = this.parseQuestions(response.data.content)

          // Get submit button
          this.submitButton = this.questions.find((question) => question.type === 'control_button')

          // Initialize values
          this.initValues()

          // Set the form to ready
          this.currentFormState = 'ready'
        })

      },
      submit: async function () {
        const formData = new FormData(this.$refs.form)
        const response = await axios.post(`https://submit.jotform.us/submit/${this.formId}/`, formData)

        if (response.status === 200) {
          // Set the form to submitted
          this.currentFormState = 'submitted'
        } else {
          // Set the form to failed
          this.currentFormState = 'failed'
        }

      }
    }
  }
</script>
