import axios from 'axios'
import is from 'is_js'
import {getUrlParams} from '../helpers'

export const getClothingProducts = async function () {
  const host = 'https://okink-cacher.now.sh/'
  const path = 'sheet/'
  const id = '1rxhq2NEaAvaNJf74jQ1HH6lCt19-5dBU6S1x3-GkFTM'
  const sheetUrl = `${host}${path}${id}`
  const response = await axios.get(sheetUrl)
  const doc = response.data
  const clothingProductsSheet = doc.worksheets.find((sheet) => sheet.title === 'Clothing')

  return clothingProductsSheet.data
}

export default async () => {
  const params = getUrlParams()
  if (is.not.propertyDefined(params, 'product_id')) throw new Error('No product_id is defined. (Ex: ?product_id=3001)')
  const productId = params.product_id
  const clothingProducts = await getClothingProducts()
  const product = clothingProducts.find((product) => Number(product.clothing_id) === Number(productId))

  return product
}
