export const trimCharacter = (string, character) => {
  let trimmie = string

  // Trim beginning
  while (trimmie.charAt(0) === character) {
   trimmie = trimmie.substr(1)
  }

  // Trim end
  while (trimmie.charAt(trimmie.length - 1) === character) {
   trimmie = trimmie.slice(0, -1)
  }

  return trimmie
}

export const formatDollars = (integer) => {
  const withTrailingZeroes = integer.toFixed(2)
  return `$${withTrailingZeroes}`
}

export const jsonFreeze = (objectOrArray) => {
  return JSON.parse(JSON.stringify(objectOrArray))
}

export const getUrlParams = (search = document.location.search) => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}
  hashes.forEach((hash) => {
    const [
      key,
      val
    ] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })

  return params
}
