export const pricingTiers = [
    // Less than 12
    {
        min: -Infinity,
        max: 11,
        baseColorCost: 0,
        costPerColor: 0,
        screenCharge: true,
    },
    // 12, 24
    {
        min: 12,
        max: 24,
        baseColorCost: 3.10,
        costPerColor: 1.20,
        screenCharge: true,
    },
    // 25, 48
    {
        min: 25,
        max: 48,
        baseColorCost: 2.00,
        costPerColor: 0.40,
        screenCharge: true,
    },
    // 49, 99
    {
        min: 49,
        max: 99,
        baseColorCost: 0.90,
        costPerColor: 0.35,
        screenCharge: true,
    },
    // 100, 249
    {
        min: 100,
        max: 249,
        baseColorCost: 0.70,
        costPerColor: 0.30,
        screenCharge: false,
    },
    // 250, 499
    {
        min: 250,
        max: 499,
        baseColorCost: 0.60,
        costPerColor: 0.30,
        screenCharge: false,
    },
    // 500 or more
    {
        min: 500,
        max: Infinity,
        baseColorCost: 0.55,
        costPerColor: 0.30,
        screenCharge: false,
    },
]