import {formatDollars} from '../helpers'

export default (key, {shirtCount, nextTier, nextTierAmount}) => {
  const nextTierDifference = nextTier.min - shirtCount
  const nextTierPrice = formatDollars(nextTierAmount)
  
  const messages = [
    {
      key: 'nextTier',
      value: `Order ${nextTierDifference} more and pay ${nextTierPrice}`,
    },
    {
      key: 'tooLow',
      value: 'Please increase number of shirts to at least 12',
    },
  ]
  
  return messages.find((set) => set.key === key)
}